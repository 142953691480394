import React, { useContext, useEffect, useState } from 'react';
import { get, head, isArray } from 'lodash';
import { bcApi } from '../../helpers/bigcommerce'
// import CartContext from '../../context/CartProvider';

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import CurrencyFormatter from "../../components/atoms/CurrencyFormatter/CurrencyFormatter"
import DateFormatter from "../../components/atoms/DateFormatter/DateFormatter"
import BillingAddress from '../../components/atoms/BillingAddress/BillingAddress';
// import ReturnsForm from '../../components/organisms/ReturnsForm/ReturnsForm';
import Icon from "../../components/atoms/Icon/Icon"
import Logo from '../../components/atoms/Logo/Logo';
// import Button from '../../components/atoms/Button/Button';
// import Dialog from '../../components/atoms/Dialog/Dialog';

import * as styles from './orders.module.css';
import ContentfulContext from '../../context/ContentfulProvider';
import ContentfulTranslationText from '../../components/atoms/ContentfulTranslationText/ContentfulTranslationText';

const fetchProductImage = productId => {
    const endpoint = `catalog/products/${productId}/images`;
    return bcApi(endpoint).then(({ response }) => {
        if (isArray(response.data)) {
        const thumb = response.data.filter(a => a.is_thumbnail === true);
        return head(thumb.length ? thumb : response.data);
        }
        return {};
    });
};

const fetchProducts = async orderId => {
    const productsEndpoint = `orders/${orderId}/products`;
    const products = await bcApi(productsEndpoint, 'GET', null, 2).then(
        ({ response, status }) => {
        if (!response || status !== 200) {
            return [];
        }
        return response;
        }
    );

    const promises = products.map(async product => {
        const image = await fetchProductImage(product.product_id);
        return { ...product, image };
    });

    return Promise.all(promises);
};

const fetchShipping = async orderId => {
    const endpoint = `orders/${orderId}/shipping_addresses`;
    const data = await bcApi(endpoint, 'GET', null, 2).then(
        ({ response, status }) => {
        if (status === 200 && response) {
            return response;
        }
        return [];
        }
    );
    return data;
};

const fetchOrders = async customerId => {
    const endpoint = `orders?customer_id=${customerId}&sort=date_created:desc`;
    const orders = await bcApi(endpoint, 'GET', null, 2).then(
        ({ response, status }) => {
        if (!response || status !== 200) {
            return [];
        }
        return response;
        }
    );

    const promises = orders.map(async order => {
        const products = await fetchProducts(order.id);
        const shipping = await fetchShipping(order.id);
        return { ...order, products, shipping_addresses: head(shipping) };
    });

    return Promise.all(promises);
};

const Orders = ({ customerId, transObj }) => {
    const translations = transObj;
    
    const [orders, setOrders] = useState([]);
    const [activated, setActivated] = useState({});
    const [fetched, toggleFetched] = useState(false);
    // const [openReturns, toggleOpenReturns] = useState(false);
    // const cartCtx = useContext(CartContext);
    // const addAllToCart = cartCtx && cartCtx.addAllToCart;

    const getOrderData = async () => {
        toggleFetched(false);
        setOrders(await fetchOrders(customerId));
        toggleFetched(true);
    };

    useEffect(() => {
        if (!fetched && customerId > 0) {
          getOrderData();
        }
        // eslint-disable-next-line
    }, [fetched, customerId]);

    const toggleViewOrder = order => () => {
        if (activated.id !== order.id) {
          setActivated(order);
        } else {
          setActivated({});
        }
    };
    
    // const getFirstProductImage = order => {
    //     const image = get(order, 'products[0].image');
    //     if (image) {
    //       return (
    //         <img loading="lazy" src={image.url_thumbnail} alt={image.description} />
    //       );
    //     }
    //     return null;
    // };
    
    // const reorder = (e, orderData) => {
    //     e.target.innerText = "Adding...";
    //     addAllToCart(orderData.products).then(response => {
    //       if (typeof window !== "undefined") {
    //         window.location = '/cart';
    //       }
    //     });
    // }

    return (
        <div className={styles.root}>
          {fetched && orders && orders.length === 0 && (
            <span><ContentfulTranslationText keyName="haveNoOrders">You have no orders</ContentfulTranslationText></span>
          )}
    
          {fetched && orders && orders.length > 0 && (
            <div className={`dataTable ${styles.dataTable}`}>
              <div className={styles.tableHeader}>
                <div data-orderid>
                  <span><ContentfulTranslationText keyName="orderNo">Order #</ContentfulTranslationText></span>
                </div>
                <div>
                  <span><ContentfulTranslationText keyName="orderPlaced">Order Placed</ContentfulTranslationText></span>
                </div>
                <div>
                  <span><ContentfulTranslationText keyName="lastUpdate">Last Update</ContentfulTranslationText></span>
                </div>
                <div data-status>
                  <span><ContentfulTranslationText keyName="status">Status</ContentfulTranslationText></span>
                </div>
                <div data-toggle></div>
              </div>
              {orders.map(order => (
                <React.Fragment key={order.id}>
                {order.status_id > 0 && (
                  <div className={styles.tableRow}>
                    <div className={styles.orderItem} role="presentation" onClick={toggleViewOrder(order)}>
                      {/* <div data-productimage>{getFirstProductImage(order)}</div> */}
                      <div data-orderid>
                        <div className={styles.bold}>
                          <ContentfulTranslationText keyName="orderNo">Order #</ContentfulTranslationText>{order.id}
                        </div>
                        <div className={styles.productSummaryMeta}>
                          {order.items_total} <ContentfulTranslationText keyName="product">product</ContentfulTranslationText>
                          {order.items_total > 1 ? 's' : ''} <ContentfulTranslationText keyName="totaling">totaling</ContentfulTranslationText>{' '}
                          <CurrencyFormatter
                            currency={order.currency_code}
                            amount={order.total_inc_tax}
                            ignoreTax={true}
                          />
                        </div>
                      </div>
                      <div data-orderplaced>
                        <div className={`${styles.bold} ${styles.responsiveLabel}`}><ContentfulTranslationText keyName="orderPlaced">Order Placed</ContentfulTranslationText></div>
                        <DateFormatter date={order.date_created} monthFormat='short' />
                      </div>
                      <div data-lastupdated>
                      <div className={`${styles.bold} ${styles.responsiveLabel}`}><ContentfulTranslationText keyName="lastUpdate">Last Update</ContentfulTranslationText></div>
                        <DateFormatter date={order.date_modified} monthFormat='short' />
                      </div>
                      <div data-status>
                        <div className={`${styles.bold} ${styles.responsiveLabel}`}><ContentfulTranslationText keyName="status">Status</ContentfulTranslationText></div>
                        <span>{order.status}</span>
                      </div>
                      <div data-toggle className={activated.id === order.id ? styles.open : ''}>
                        <Icon symbol='caretDown' />
                      </div>
                    </div>
    
                    {activated.id === order.id && (
                      <div className={styles.orderDetail}>
                        <div className="show">
                          <div className={styles.orderBillingAddress}>
                            <BillingAddress
                              title={get(translations, 'shipTo.title', "Ship to")}
                              address={activated.shipping_addresses}
                            />
                            <BillingAddress
                              title={get(translations, 'billTo.title', "Bill to")}
                              address={activated.billing_address}
                            />
                          </div>
                          <div className={styles.orderDetailTable}>
                            {activated.products?.map(product => {
                              return (
                                <div
                                  key={product.product_id}
                                  className={styles.orderDetailRow}
                                >
                                  <div>
                                    {product.image ? (
                                      <img
                                        loading="lazy"
                                        src={get(product, 'image.url_thumbnail') || null}
                                        alt={get(product, 'image.description') || ''}
                                      />
                                    ) : (
                                      <Logo />
                                    )}
                                  </div>
                                  <div>
                                    <div className={`${styles.bold} ${styles.productName}`}>{product.name}</div>
                                    <div className={styles.productMeta}><ContentfulTranslationText keyName="qty">Qty</ContentfulTranslationText>: {product.quantity}</div>
                                    {product.product_options.length > 0 &&
                                      product.product_options.map(
                                        (option, optionIndex) => (
                                          <div className={styles.productMeta} key={optionIndex}>
                                            {option.display_name}:
                                            {option.display_value}
                                          </div>
                                        )
                                      )}
                                  </div>
                                  <div className={`flex-end ${styles.bold} ${styles.itemPrice}`}>
                                    <CurrencyFormatter
                                      currency={order.currency_code}
                                      amount={product.total_inc_tax}
                                      ignoreTax={true}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className={styles.summary}>
                            <div className={styles.summaryActions}>
                              {/* <div className="flex-between">
                                <Button level="secondary" type="button" onClick={(e) => reorder(e, order)}>Reorder</Button>
                                <Button level="secondary" type="button" onClick={(e) => toggleOpenReturns(!openReturns)}>Return items</Button>
                              </div>
                              <Dialog title="Returns request" hideBtnOk={true} cancelBtnText="Close" open={openReturns} onCancel={() => toggleOpenReturns(false)}>
                                <ReturnsForm orderDetails={order} />
                              </Dialog> */}
                            </div>
                            <div className={styles.summaryInner}>
                              <div>
                                <span><ContentfulTranslationText keyName="subtotal">Subtotal</ContentfulTranslationText>:</span>
                                <span className={`${styles.bold} ${styles.itemPrice}`}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.subtotal_inc_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
                              {order?.coupon_discount && order?.coupon_discount > 0 && (
                                <div>
                                  <span><ContentfulTranslationText keyName="couponDiscount">Coupon discount</ContentfulTranslationText>:</span>
                                  <span className={`${styles.bold} ${styles.itemPrice}`}>
                                    <CurrencyFormatter
                                      currency={order.currency_code}
                                      amount={order.coupon_discount}
                                      ignoreTax={true}
                                    />
                                  </span>
                                </div>
                              )}
    
                              {order?.gift_certificate_amount && order?.gift_certificate_amount > 0 && (
                                <div>
                                  <span><ContentfulTranslationText keyName="giftCertificate">Gift certificate</ContentfulTranslationText>:</span>
                                  <span className={`${styles.bold} ${styles.itemPrice}`}>
                                    <CurrencyFormatter
                                      currency={order.currency_code}
                                      amount={order.gift_certificate_amount}
                                      ignoreTax={true}
                                    />
                                  </span>
                                </div>
                              )}
    
                              <div>
                                <span><ContentfulTranslationText keyName="shipping">Shipping</ContentfulTranslationText>:</span>
                                <span className={`${styles.bold} ${styles.itemPrice}`}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.shipping_cost_inc_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
    
                              <div>
                                <span><ContentfulTranslationText keyName="tax">GST</ContentfulTranslationText>:</span>
                                <span className={`${styles.bold} ${styles.itemPrice}`}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.total_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
    
                              <div>
                                <span><ContentfulTranslationText keyName="grandTotal">Grand Total</ContentfulTranslationText>:</span>
                                <span className={`${styles.bold} ${styles.itemPrice}`}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.total_inc_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                </React.Fragment>
              ))}
            </div>
          )}
    
          {!fetched && <span><ContentfulTranslationText keyName="fetchingOrderHistory">Fetching your order history</ContentfulTranslationText>...</span>}
        </div>
    );
    
}

const OrdersOutput = () => {
  const contentful = useContext(ContentfulContext);
  const translations = get(contentful, 'translationData', {});

  return (
    <AccountPageWrapper metaTitle="Account - Orders" title={get(translations, 'orders.title', 'Orders')}>
        <Orders transObj={translations} />
    </AccountPageWrapper>
  )
}

export default OrdersOutput