// extracted by mini-css-extract-plugin
export var root = "orders-module--root--Mc8YD";
export var loaderContainer = "orders-module--loaderContainer--wWMSe";
export var tableHeader = "orders-module--tableHeader--vSHRf";
export var orderItem = "orders-module--orderItem--DvFd9";
export var bold = "orders-module--bold--fbfgS";
export var responsiveLabel = "orders-module--responsiveLabel--ZnzJa";
export var open = "orders-module--open--3gWz2";
export var orderDetail = "orders-module--orderDetail--a0gQj";
export var orderDetailTable = "orders-module--orderDetailTable--u53rZ";
export var orderDetailRow = "orders-module--orderDetailRow--PmRum";
export var tableRow = "orders-module--tableRow--5Zv5c";
export var orderBillingAddress = "orders-module--orderBillingAddress--Qpnf+";
export var summary = "orders-module--summary--AJ2Cl";
export var summaryInner = "orders-module--summaryInner--qk1n5";
export var dataTable = "orders-module--dataTable--YGNex";
export var summaryActions = "orders-module--summaryActions--r3hqz";
export var paymentDetails = "orders-module--paymentDetails--Wfp5z";
export var productSummaryMeta = "orders-module--productSummaryMeta--tPNfl";
export var productMeta = "orders-module--productMeta--J3WvJ";
export var itemPrice = "orders-module--itemPrice--rxp25";
export var productName = "orders-module--productName--lLga0";