const DateFormatter = ({ date, monthFormat = 'long' }) => {
    const dateObj = new Date(date);
    const languageCode =
      typeof window !== 'undefined'
        ? window.navigator.language || 'en-AU'
        : 'en-AU';
    const formattedDate = new Intl.DateTimeFormat(languageCode, {
      day: 'numeric',
      month: monthFormat,
      year: 'numeric'
    }).format(dateObj);
    return dateObj && formattedDate;
  };
  
  export default DateFormatter;